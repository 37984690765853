
























import { Component, Vue } from 'vue-property-decorator'
import OwnerDayList from '@/components/owner/OwnerDayList.vue'
import CompanySelect from '@/components/manage/CompanySelect.vue'
import firebase from '@/plugins/firebase'
import { Company } from '@/models/company'
import CompanyNotFound from '@/components/common/CompanyNotFound.vue'
import moment from '@/plugins/moment'
import DatePicker from '@/components/common/DatePicker.vue'
@Component<OwnerDayIndex>({
  components: { OwnerDayList, CompanySelect, CompanyNotFound, DatePicker }
})
export default class OwnerDayIndex extends Vue {
  company: firebase.firestore.DocumentSnapshot<Company> | null = null
  dates = [
    moment().startOf('months').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD')
  ]

  changeDates (dates: string[]): void {
    this.dates = dates
  }
}
