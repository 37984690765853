

































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import dayCollection, { Day } from '@/models/day'
import DayItem from '@/components/common/DayItem.vue'
import deviceCollection, { Device } from '@/models/device'
import AuthStore from '@/store/AuthStore'
import moment from '@/plugins/moment'
@Component<OwnerDayList>({
  components: {
    DayItem
  },
  mounted () {
    this.clear()
    this.fetch()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class OwnerDayList extends Vue {
  @Prop({ type: Array, required: true }) dates!: string[]
  unsubscribe: firebase.Unsubscribe | null = null
  docs: firebase.firestore.QueryDocumentSnapshot<Day>[] = []
  limit = 6
  empty = false
  loading = false

  get last (): firebase.firestore.DocumentSnapshot<Day> | null {
    if (!this.docs.length) return null
    return this.docs[this.docs.length - 1]
  }

  get phoneNumber (): string {
    if (!AuthStore.firebaseUser) return ''
    return AuthStore.firebaseUser.phoneNumber || ''
  }

  get deviceRef (): firebase.firestore.DocumentReference<Device> {
    return deviceCollection.doc(this.phoneNumber)
  }

  get ref (): firebase.firestore.Query<Day> {
    return dayCollection.where('device', '==', this.deviceRef)
  }

  clear (): void {
    this.docs = []
  }

  async fetch (): Promise<void> {
    if (!this.phoneNumber) return
    try {
      this.loading = true
      let query = this.ref
        .where('firstSecond.time', '>=', moment(this.dates[0]).utcOffset(9).toISOString())
        .where('firstSecond.time', '<', moment(this.dates[1]).utcOffset(9).add(1, 'days').toISOString())
        .orderBy('firstSecond.time', 'asc')
      query = this.last ? query.startAfter(this.last) : query
      const sn = await query.limit(this.limit).get()
      this.empty = sn.empty
      if (sn.empty) return
      this.docs.push(...sn.docs)
    } finally {
      this.loading = false
    }
  }

  onIntersect (entries: unknown, observer: unknown, isIntersecting: boolean): void {
    if (!this.docs.length) return
    if (isIntersecting) this.fetch()
  }
}
